import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "imagefactory",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#imagefactory",
        "aria-label": "imagefactory permalink",
        "className": "anchor before",
        "parentName": "h1"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`ImageFactory`}</h1>
    <p>{`(formerly known as `}
      <em {...{
        "parentName": "p"
      }}>{`Hardened Base Image`}</em>
      {`, or `}
      <em {...{
        "parentName": "p"
      }}>{`HBI`}</em>
      {`)`}</p>
    <p>{`Based on Nordcloud's extensive experience of managing cloud services, ImageFactory is the bomb-proof SaaS solution for fully managing image-hardening for multicloud.`}</p>
    <h2 {...{
      "id": "imagefactory-offers",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#imagefactory-offers",
        "aria-label": "imagefactory offers permalink",
        "className": "anchor before",
        "parentName": "h2"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`ImageFactory offers:`}</h2>
    <ul>

      <li {...{
        "parentName": "ul"
      }}>{`a hardening solution which is based on the most relevant and highly regarded security standards`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`support for AWS, Azure, GCP and VMWare, along with most common Windows and Linux versions`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`automatic delivery updated images to all your cloud accounts and subscriptions`}</li>

    </ul>
    <h3 {...{
      "id": "for-example-with-imagefactory-you-can",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#for-example-with-imagefactory-you-can",
        "aria-label": "for example with imagefactory you can permalink",
        "className": "anchor before",
        "parentName": "h3"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`For example, with ImageFactory, you can:`}</h3>
    <ul>

      <li {...{
        "parentName": "ul"
      }}>{`build solutions which are guaranteed as secure for distributed applications`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`insert OS images which are guaranteed as secure into your automated CI/CD pipelines`}</li>

    </ul>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      